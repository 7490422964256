<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true"/>
		<AppTitle :title="tableName"/>
		<div class="flex space-x-6 mt-4">
			<router-link :to="{name : 'table-lobby', params: { uuid: table.uuid }}" class="font-bold text-lg text-gray-300" v-if="userIsFinished">Table</router-link>
			<router-link :to="{name : 'chat', params: { uuid: table.uuid }}" class="font-bold text-lg text-red-500">Chat</router-link>
		</div>
		<div class="pt-4 pb-8 text-gray-400 h-full flex flex-col">
			<div class="flex-1 flex flex-col justify-end space-y-4 overflow-y-scroll" id="chat_container_2" v-if="messages.length">

				<div 
					class="w-3/4" 
					:class="message.user.uuid === currentUser.uuid ? 'ml-auto' : ''"
					v-for="message in messages" 
					:key="message.id"
				>
					<div 
						class="rounded-b-xl px-4 py-2 text-sm" 
						:class="message.user.uuid === currentUser.uuid ? 'bg-red-500 text-white rounded-l-xl' : 'bg-white text-gray-700 rounded-r-xl'"
						v-html="message.message"
					>
					</div>
					<div class="mt-2 flex">
						<div :class="message.user.uuid === currentUser.uuid ? 'flex-0 order-2' : 'flex-1 order-1'">
							<user-profile-picture 
								:user="message.user"
								:size="8"
							/>
						</div>
						<div class="text-gray-400 text-xs" :class="message.user.uuid === currentUser.uuid ? 'flex-1 order-1' : 'order-2'">{{ formatTime(message.time) }}</div>
					</div>
				</div>

			</div>
			<div v-if="!messages.length" class="flex-1 pt-2">
				<p class="text-sm text-gray-400">There are no messages in this table. Write something down below!</p>
			</div>
			<div class="flex-0 mt-8">
				<form @submit.stop.prevent="sendChat">
					<div class="relative">
						<div class="absolute inset-y-0 right-0 pl-3 flex items-center">
							<button :disabled="loading" class="text-red-500 text-sm font-semibold focus:bg-red-100 px-4 py-3 rounded-r-xl">Send</button>
						</div>
						<input type="text" class="block w-full pl-4 pr-16 py-3 rounded-xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500 text-sm" placeholder="Enter message..." v-model="message">
					</div>
				</form>
			</div>

		</div>
	</div>
</template>

<script type="text/javascript">
	import moment from 'moment';
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import UserProfilePicture from '@/components/UserProfilePicture'
	export default {
		components: {
			TopBar,
			AppTitle,
			UserProfilePicture,
		},
		created(){
			this.updateNow();
		},
		mounted(){
			this.setupChat();
		},
		beforeDestroy(){
			this.destroyChat();
		},
		data(){
			return {
				message: "",
				channel: null,
				now: null,
				loading: false
			}
		},
		computed: {
			userIsFinished(){
				return this.table ? this.table.answers.step >= 6 : false;
			},
			currentUser(){
				return this.$store.state.auth.user;
			},
			table() {
				return this.$store.state.table.table;
			},
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			messages() {
				return this.$store.state.table.messages;
			},
			pusher(){
				return this.$store.state.pusher;
			},
		},
		methods: {
			updateNow(){
				this.now = moment();
				setTimeout(() => {
					this.updateNow();
				}, 30000);
			},
			formatTime(date){
				return moment.utc(date, "YYYY-MM-DD hh:mm:ss").diff(moment(this.now), 'seconds') < 0
				? moment.utc(date, "YYYY-MM-DD hh:mm:ss").from(moment(this.now))
				: "a few seconds ago";
			},
			sendChat(){
				if (this.message.length === 0) return;
				this.loading = true;
				this.$store.dispatch('table/postChat', {
					message: this.message,
					uuid: this.table.uuid
				}).then( r => {
					this.message = "";
					this.updateChat(r.data);
				}).finally( () => {
					this.loading = false;
				});
			},
			scrollToBottom(){
				setTimeout( () => {
					let container = document.getElementById("chat_container_2");
					container.scrollTo(0, container.scrollHeight);
				},150);
			},
			setupChat(){
				this.$store.dispatch('table/getChat', this.table.uuid).then( () => {
					this.scrollToBottom();
				});
				this.channel = this.pusher.subscribe(`table-${this.table.uuid}`);
				this.channel.bind('new-message', data => {
					this.updateChat(data.message);
				});
			},
			updateChat(message){
				if (!this.messages.find( x => x.id === message.id )){
					this.messages.push(message);
					this.scrollToBottom();
				}
			},
			destroyChat(){
				this.$store.commit('table/SET_MESSAGES', []);
			}
		}
	}
</script>